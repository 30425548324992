import { getIconNameFromFileName } from 'BambooHR.util';
import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const JADE_ENABLED = isEnabled('jade');

var context_timeout = 250;
var context_closetimer = 0;
var context_menu_for = false;
const validExtensionsList = window.GLOBAL_VALID_EXTENSIONS ? Array.from(window.GLOBAL_VALID_EXTENSIONS) : ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'jpg', 'jpeg', 'tif', 'tiff', 'gif', 'png', 'bmp', 'txt', 'rtf', 'zip'];
const validExtensions = validExtensionsList.map(ext => '.' + ext);

$(document).ready(function() {

	$('.files ul:not(.contextmenu)').bind("contextmenu",function(e) {
		return false;
	});
	$('h3.file-title:not(.contextmenu)').bind("contextmenu",function(e) {
		return false;
	});

	$(".files ul.context").hover(
		function(e) {
			$(this).addClass("hover")
		},
		function(e) {
			if ($(this)[0] != context_menu_for[0]) {
				$(this).removeClass("hover");
			}
		}
	).bind("contextmenu",showFileContextMenu);

	$(".files a.newcontext").bind("click", showFileContextMenu);
	function showFileContextMenu(e) {
		$('#context-menu').css({
			position: 'absolute',
			top: e.pageY - 2 + 'px',
			left: e.pageX - 2 + 'px'
		})
			.html(
				getFileContextMenu(getFileId($(this).closest('.files ul.context').attr('id')),$(this).closest('.files ul.context').children('li').attr('id'))
			)
			.show()
			.click(closeContextMenu);
		context_menu_for = $(this);
		return false;
	}

	$("h3.file-title.context")
		.bind("contextmenu", function(e) {
			var theHtml = '';
			if ($(this).hasClass('emp')) {
				theHtml = getEmployeeCategoryContextMenu(getCategoryId($(this).attr('id')));
			} else {
				theHtml = window.getCategoryContextMenu(getCategoryId($(this).attr('id')));
			}
			if (!theHtml) {
				return false;
			}
			$('#context-menu').css({
				position: 'absolute',
				top: e.pageY - 2 + 'px',
				left: e.pageX - 2 + 'px'
			})
				.html(
					theHtml
				)
				.show()
				.click(closeContextMenu);
			context_menu_for = $(this);
			return false;
		})
		.hover(
			function(e) {
				$(this).addClass("hover");
				$(this).find("span.edit-cat").unbind("click", categoryClick);
			},
			function(e) {
				if ($(this)[0] != context_menu_for[0]) {
					$(this).removeClass("hover");
				}
			}
		);
	$("h3.file-title").bind("click",categoryClick);

	function categoryClick(e) {
		var tar = $(e.target);
		if (tar.hasClass('prevent_bubble')) {
			return false;
		}
		$(this).toggleClass('closed');
		var file_list_id = '#files_' + getCategoryId($(this).attr('id'));
		$(file_list_id).toggleClass('closed');
		var open = $(file_list_id).hasClass('closed') ? "false" : "true";
		var all = $("h3.file-title").length;
		if (all > 1) {
			var cnt = $("h3.file-title.closed").length;
			if (cnt == 0) {
				// if all are open show the collapse all option
				$('a#expand-files').hide();
				$('a#collapse-files').show();
			}

		}
		// save the state in the session
		var urlStr = false;
		if ($(this).hasClass("emp_session")) {
			urlStr = "employees/files/employee_file_section_status.php";
		} else {
			urlStr = "company_file_section_status.php";
		}
		if (urlStr) {
			$.ajax({
				url: '/ajax/' + urlStr + '?id=' + getCategoryId($(this).attr('id')) + "&open=" + open,
				type: 'get',
				dataType: 'html',
				async: false,
				success: function(data) {}
			});
		}


	}

	$("a#select-all").click(function() {
		$(".files input:checkbox").each(function() {
			this.checked = true
		});
		checkSelectState();
		return false;
	});

	$("a#unselect-all").click(function() {
		$(".files input:checkbox").each(function() {
			this.checked = false
		});
		checkSelectState();
		return false;
	});

	$("a#expand-files").click(function() {
		$("h3.file-title").removeClass('closed');
		$("div.file-list").removeClass('closed');
		$("a#expand-files").hide();
		$("a#collapse-files").show();
		// if it's employee files then save the state in the session
		var urlStr = false;
		if ($(this).hasClass("emp_session")) {
			urlStr = "employees/files/employee_file_section_status.php";
		} else {
			urlStr = "company_file_section_status.php";
		}
		if (urlStr) {
			var sectionids = $(this).data('section-ids');
			$.ajax({
				url: '/ajax/' + urlStr + '?ids=' + sectionids + '&open=true',
				type: 'get',
				dataType: 'html',
				async: false,
				success: function(data) {}
			});
		}
		return false;
	});

	$("a#collapse-files").click(function() {
		$("h3.file-title").addClass('closed');
		$("div.file-list").addClass('closed');
		$("a#collapse-files").hide();
		$("a#expand-files").show();
		// if it's employee files then save the state in the session
		var urlStr = false;
		if ($(this).hasClass("emp_session")) {
			urlStr = "employees/files/employee_file_section_status.php";
		} else {
			urlStr = "company_file_section_status.php";
		}
		if (urlStr) {
			var sectionids = $(this).data('section-ids');
			$.ajax({
				url: '/ajax/' + urlStr + '?ids=' + sectionids + '&open=false',
				type: 'get',
				dataType: 'html',
				async: false,
				success: function(data) {}
			});
		}
		return false;
	});

	$(".files input:checkbox").change(function() {
		checkSelectState();
	});

	$("#context-menu").bind('mouseover', canceltimer).bind('mouseout', timer)

}) //end document.ready
	.click(closeContextMenu);

function timer() {
	context_closetimer = window.setTimeout(closeContextMenu, context_timeout);
}

function canceltimer() {
	if (context_closetimer != 0) {
		window.clearTimeout(context_closetimer);
		context_closetimer = 0;
	}
}

function closeContextMenu() {
	if (context_menu_for != false) {
		$('#context-menu').hide();
		$(context_menu_for).removeClass("hover");
		context_menu_for = false;
	}
}

function checkSelectState() {
	var num_files_selected = $(".files input:checkbox:checked").length;
	if ($(".files input:checkbox").length == num_files_selected) {
		$("a#select-all").hide();
		$("a#unselect-all").show();
	} else {
		$("a#select-all").show();
		$("a#unselect-all").hide();
	}

	if (num_files_selected > 0) {
		$("a#download-selected").fadeIn();
		if (selectedFilesAreAllWritable()) {
			$("a#move-selected").fadeIn();
			$("a#delete-selected").fadeIn();
		} else {
			$("a#move-selected").hide();
			$("a#delete-selected").hide();
		}
	} else {
		$("a#download-selected").hide();
		$("a#move-selected").hide();
		$("a#delete-selected").hide();
	}

	highlightSelected();
}

function highlightSelected() {
	$(".files input:checkbox").each(function() {
		if (this.checked) {
			$(this).parents("ul").addClass("selected");
		} else {
			$(this).parents("ul").removeClass("selected");
		}
	});
}

function getNumEmployeeFiles(cat_id) {
	var ret = null;
	$.ajax({
		url: '/ajax/employee_files.php?id=' + cat_id,
		type: 'get',
		dataType: 'html',
		async: false,
		success: function(data) {
			ret = data;
		}
	});
	return ret;
}

function getEmployeeCategoryContextMenu(id) {
	if (typeof files_comment_categories != "undefined" && $.inArray(id, window.files_comment_categories) >= 0) {
		return '';
	}
	var menu = '<li><a href="#" onclick="return renameCategory(' + id + ')">Rename</a></li>';

	var catHasFiles = true;
	let numFiles = getNumEmployeeFiles(id);
	if (numFiles == 0) { catHasFiles = false; }

	if (!catHasFiles && (typeof files_keep_categories == "undefined" || $.inArray(id, window.files_keep_categories) == -1)) {
		menu += '<li><a href="#" onclick="return deleteCategory(' + id + ')">' + $.__('Delete') + '</a></li>';
	}
	return menu;
}

function getFileContextMenu(id,share) {
	var menu = '<li><a href="download.php?id=' + id + '">' + $.__('Open') + '</a></li>';
	menu += '<li><a href="#" onclick="return renameFile(' + id + ')">' + $.__('Rename') + '</a></li>';
	menu += '<li><a href="replace.php?id=' + window.files_replace_params + id + '">' + $.__('Replace') + '</a></li>';
	menu += '<li><a href="#" onclick="return moveFile(' + id + ')">' + $.__('Move') + '</a></li>';
	if (share) {
		menu += '<li><a href="#" onclick="return unshareFile(' + id + ',true)">' + $.__('Share') + '</a></li>';
	} else {
		menu += '<li><a href="#" onclick="return unshareFile(' + id + ',false)">' + $.__('Unshare') + '</a></li>';
	}
	menu += '<li><a href="#" onclick="return deleteFile(' + id + ')">' + $.__('Delete') + '</a></li>';
	return menu;
}
// deprecated
function addCategory() {
	window.setMessage('deprecated add category in file management.js', 'error');
	return ajaxModalDialog(
		$.__("Add a new category"), window.files_ajax_url + "add_category.php",
		$.__("Save"),
		function() {
			if ($.trim($('#add_category input').val()) == '') {
				var please = $.__("Please enter a category name.");
				if ($('#add_category p.please').html() != please) {
					$('#add_category').append("<p class='please'>" + please + "</p>");
				}
			} else {
				modalFormSubmit($('#add_category')[0], function(result) {
					if (result.ret == "SAVED") {
						document.location.reload();
					}
				});
			}
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel')
		}
	);
}
// deprecated
function renameCategory(id) {
	window.setMessage('deprecated add category in file management.js', 'error');

	return ajaxModalDialog(
		$.__("Rename Selected Category"), window.files_ajax_url + "rename_category.php?id=" + id,
		$.__("Save"),
		function() {
			if ($.trim($('#rename_category input').val()) == '') {
				var please = $.__("Please enter a category name.");
				if ($('#rename_category p.please').html() != please) {
					$('#rename_category').append("<p class='please'>" + please + "</p>");
				}
			} else {
				modalFormSubmit($('#rename_category')[0], function(result) {
					if (result.ret == "SAVED") {
						//$('#category_'+result.id).html(result.name);
						document.location.reload();
					}
				});
			}
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel')
		}
	);
}

function deleteCategory(id) {
	return ajaxModalDialog(
		$.__("Delete Selected Category"), window.files_ajax_url + "delete_category.php?id=" + id,
		$.__("Yes"),
		function() {
			modalFormSubmit($('#delete_category')[0], function(result) {
				if (result.ret == "DELETED") {
					//$('#category_'+result.id).fadeOut();
					//$('#files_'+result.id).fadeOut();
					document.location.reload();
				}
			});
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel')
		}
	);
}

function moveFile(id) {
	return moveFiles([id]);
}

function moveSelectedFiles() {
	var count = 0;
	var file_ids = [];
	$(".files input:checkbox:checked").each(function() {
		file_ids[count] = getFileId($(this).parents('ul').attr('id'));
		count++;
	});
	return moveFiles(file_ids);
}

function moveFiles(file_ids) {
	if (file_ids.length > 0) {
		var get_file_ids = '';
		for (var i = 0; i < file_ids.length; i++) {
			get_file_ids += "&file_ids[]=" + file_ids[i]
		}
		var title = $.__n("Move Selected Document", "Move Selected Documents", file_ids.length);

		return ajaxModalDialog(
			title,
			window.files_ajax_url + "move_files.php?tmp=1&" + get_file_ids,
			$.__("Move"),
			function() {
				modalFormSubmit($('#move_files')[0], function(result) {
					if (result.ret == "MOVED") {
						document.location.reload();
					}
				});
			}, undefined, {
				modal: true,
				cancel_message: $.__("Cancel")
			});
	}
	return false;
}

function unshareFile(id,share) {
	var shareText = $.__("Unshare Selected Document");
	if (share) {
		shareText = $.__("Share Selected Document");
	}
	return ajaxModalDialog(
		shareText,
		window.files_ajax_url + "unshare_file.php?share=" + share + "&id=" + id,
		$.__("Yes"),
		function() {
			modalFormSubmit($('#unshare_file')[0], function(result) {
				if (result.ret == "SAVED") {
					document.location.reload();
				}
			});
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__("Cancel")
		}
	);
}

/** delete employee file */
export function deleteFile(id) {
	return deleteFiles([id]);
}
export function deleteCompanyCommentFile(id) {
	return deleteFiles([id],true);
}
function deleteSelectedFiles() {
	var count = 0;
	var file_ids = [];
	$(".files input:checkbox:checked").each(function() {
		file_ids[count] = getFileId($(this).parents('ul').attr('id'));
		count++;
	});
	return deleteFiles(file_ids);
}

/** delete employee files **/
function deleteFiles(file_ids, company_file) {
	return new Promise((resolve, reject) => {
		company_file = company_file || false;
		if (file_ids.length > 0) {
			let get_file_ids = '';
			for (let i = 0; i < file_ids.length; i++) {
				get_file_ids += "&file_ids[]=" + file_ids[i]
			}

			const title = $.__n("Delete Selected Document", "Delete Selected Documents",file_ids.length);
			let url = window.files_ajax_url + "delete_files.php?tmp=1" + get_file_ids;
			if (company_file) {
				url += "&company=1";
			}

			$.ajax({
				url,
				method: 'GET'
			}).done((html) => {
				setupModal(html, title);
				return resolve();
			}).fail(() => {
				setMessage($.__('It looks like this file has already been deleted.'), 'success');
				return reject();
			});
		}
	});

	function setupModal(html, title) {
		if (JADE_ENABLED) {
			window.BambooHR.Modal.setState({
				dangerousContent: `<div class="Comment__deleteFileModalWrapper">${ html }</div>`,
				icon: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-43x48'),
				iconColor: 'danger',
				iconV2Color: 'error-strong',
				isOpen: true,
				primaryAction: () => {
					const $form = $('form#delete_files');
					const action = $form.attr('action');
					const params = $form.serializeArray();

					window.BambooHR.Modal.setState({ isProcessing: true }, true);

					$.ajax({
						url: action,
						data: params,
						dataType: 'json'
					}).done((result) => {
						window.BambooHR.Modal.setState({isOpen: false });

						if (result.ret == 'DELETED') {
							document.location.reload();
						}
					}).fail(() => {
						window.BambooHR.Modal.setState({ isProcessing: false }, true);
					});
				},
				primaryActionText: $.__('Yes'),
				title: title,
			});
			return;
		}

		SimpleModal.openModal({
			html,
			classes: 'jquery-padding',
			header: {
				title: {
					text: title
				}
			},
			footer: {
				buttons: {
					primary: {
						text: $.__('Yes'),
						action: ($modal) => {
							const $form = $modal.find('form#delete_files');
							const action = $form.attr('action');
							const params = $form.serializeArray();
							$.ajax({
								url: action,
								data: params,
								dataType: 'json'
							}).done((result) => {
								if (result.ret == 'DELETED') {
									document.location.reload();
								}
							});
						}
					},
					cancel: {
						show: true
					}
				}
			}
		});
	}
}

/**
 * doesn't look like this is being used anywhere
 function deleteFilesWithoutConfirmation(file_ids){
	if (file_ids.length > 0) {
		var get_file_ids = '';
		for (var i=0; i<file_ids.length; i++) {
			get_file_ids += "&file_ids[]="+file_ids[i]
		}

		return $.ajax({
			url: files_ajax_url+"delete_files.php?tmp=1"+get_file_ids+"&delete=1&showMessage=no",
			datatype: 'json'
		}).done(function(data){
			if(data.ret=="DELETED") return true;
			return false;
		});
	}
	return false;
}

 function deleteFileWithoutConfirmation(id){
	return deleteFilesWithoutConfirmation([id]);
}*/
// used for deleting comment attachments - not sure exactly when it occurs
export function deleteTempFile(file_ids) {
	if (typeof file_ids != 'object') {
		file_ids = [file_ids];
	}
	if (file_ids.length > 0) {
		var get_file_ids = '';
		for (var i = 0; i < file_ids.length; i++) {
			get_file_ids += "&file_ids[]=" + file_ids[i]
		}
		return $.ajax({
			type: 'post',
			url: window.files_ajax_url + "delete_files.php?temporary=1" + get_file_ids + "&delete=1&showMessage=no",
			datatype: 'json'
		}).done(function(data) {
			if (data.ret == "DELETED") { return true; }
			return false;
		});
	}
}
// deprecated
function downloadSelectedFiles() {
	var count = 0;
	var get_file_ids = '';
	var now = new Date();
	$(".files input:checkbox:checked").each(function() {
		get_file_ids += "&file_ids[]=" + getFileId($(this).parents('ul').attr('id'));
		count++;
	});
	window.location = window.files_base_url + "download.php?stop=stop" + get_file_ids + "&t=" + now.getTime();
	return false;
}
function getCategoryId(idname) {
	return Number(idname.substr(9));
}

export function getFileId(idname) {
	return Number(idname.substr(5));
}

function selectedFilesAreAllWritable() {
	let $limitedFiles = $('ul.file-list:not(.context) input:checked');
	return $limitedFiles.length == 0;
}

var selectmenu = document.getElementById("sortby");
if (selectmenu) {
	selectmenu.onchange = function() {
		window.location = window.files_index_url + "&sort=" + this.options[this.selectedIndex].value;
	}
}
export function scanEmployeeFiles(timeout) {
	if (!timeout) {
		timeout = 2000;
	}
	var ids = [];
	$('.update-status').each(function(idx) {
		var t = $(this).attr('id').split('_');
		ids.push(t[1]);
	});
	if (ids.length) {
		getEmployeeFileScanned(ids.join(','),timeout);
	}
}
// checks to see if the employee file has finished scanning
function getEmployeeFileScanned(fileIds,timeout) {
	if (!fileIds) { return; }
	$.ajax({
		url: '/ajax/employee_file_status.php',
		type: 'get',
		data: {
			ids: fileIds
		},
		dataType: 'json',
		async: false,
		success: function(data) {
			for (var fileId in data) {
				var ele = $('#file_' + fileId);
				if (data[fileId] == 'uploaded') {
					ele.removeClass('update-status');
					$('.file_virus_' + fileId).hide();
					$('.file_inactive_' + fileId).hide();
					$('.file_active_' + fileId).show();
					$('.file_scanning_' + fileId).hide();
					//				} else if (data[fileId] == 'uploading') {
					//				} else if (data[fileId] == 'scanning') {
				} else if (data[fileId] == 'virus') {
					ele.removeClass('update-status');
					$('.file_scanning_' + fileId).hide();
					$('.file_active_' + fileId).hide();
					$('.file_virus_' + fileId).show();
					$('.file_inactive_' + fileId).show();
				}

			}
			if (timeout < 120000) {
				timeout *= 1.5;
				window.setTimeout(function() {
					scanEmployeeFiles(timeout);
				},
				timeout);
			}

		}
	});
}
// checks to see if the company file has finished scanning
function scanCompanyFiles(timeout) {
	if (!timeout) {
		timeout = 2000;
	}
	var ids = [];
	$('.update-status').each(function(idx) {
		var t = $(this).attr('id').split('_');
		ids.push(t[1]);
	});
	if (ids.length) {
		getFileScanned(ids.join(','),timeout);
	}
}
// checks to see if the employee file has finished scanning
function getFileScanned(fileIds,timeout) {
	if (!fileIds) { return; }
	$.ajax({
		url: '/ajax/file_status.php',
		data: {
			ids: fileIds
		},
		dataType: 'json',
		async: false,
		success: function(data) {
			for (var fileId in data) {
				var ele = $('#file_' + fileId);
				if (data[fileId] == 'uploaded') {
					ele.removeClass('update-status');
					$('.file_scanning_' + fileId).hide();
					$('.file_virus_' + fileId).hide();
					$('.file_inactive_' + fileId).hide();
					$('.file_active_' + fileId).show();
					//					$('#file_'+fileId+" .previewNotReady").show();
				} else if (data[fileId] == 'virus') {
					ele.removeClass('update-status');
					$('.file_scanning_' + fileId).hide();
					$('.file_active_' + fileId).hide();
					$('.file_virus_' + fileId).show();
					$('.file_inactive_' + fileId).show();
				}

			}
			if (timeout < 120000) {
				timeout *= 1.5;
				window.setTimeout(function() {
					scanCompanyFiles(timeout);
				},
				timeout);
			}

		}
	});
}

function fileUploader() {
	this.options = {};
	this.uploaders = [];

	this.handleDragOver = function(e) {
		e.stopPropagation();
		e.preventDefault();
	};

	this.setDragOffValues = function(item) {
		item = $(item.uploaderId);
		$('.dragOver',item).find('.links-msg').hide();
		$('.dragOver',item).removeClass('dragOver');
	};

	this.remove = function(element, config = {}) {
		element.remove();
		if (config.onRemove) {
			config.onRemove.call(config);
		}
	};

	this.fallbackSuccess = function(data, config) {
		if (data.status == 'OK') {
			var elm = config.createFileWrapper(data.name,false);
			elm.data('fileInfo',data);
			elm.data('newFile',true);
			elm.attr('id','file-' + data.fid);
			elm.addClass('uploadDone');
			config.wrapper.find('.files').append(elm);
			config.wrapper.find('.file-x').bind('click',{fp: elm},function(e) {
				e.stopPropagation();
				e.preventDefault();
				if (config.removeFile(e.data.fp)) {
					$.fileUploader.remove(e.data.fp);
				}
			});
			if (config.onFallbackSuccess) {
				config.onFallbackSuccess(data, elm);
			}
			if (config.success) {
				config.success(data, elm);
			}
			if (config.required) {
				if (elm.closest('.fieldDiv').find('input[name=uploadValidatorHiddenInput]').valid()) {
					elm.closest('.fieldDiv').find('.fieldInfo.error').remove();
				}
			}
		} else {
			setMessage(data.message,'error');
		}
		$.fileUploader.uploaders[data.uploaderId] = false;
	};

	this.createFileWrapper = function(name,withProgress) {
		const templateProgress = `
			<div class="files-progress-wrapper">
				<div class="ProgressBar ProgressBar--small">
					<div class="ProgressBar__bar"></div>
				</div>
			</div>
		`;

		const template = `
			<div class="file-wrapper newFile">
				<div class="file-name">
					<ba-file-icon class="FileType__icon file-nameIcon"></ba-file-icon>
					<span class="truncate file-nameText">${ xmlEntities(name) }</span>
					<button class="fab-FloatingIconButton fab-FloatingIconButton--secondary fab-FloatingIconButton--noBounding file-x">
						<ba-icon
							name="fab-circle-x-14x14"
							class="fab-FloatingIconButton__icon"
							encore-class="fab-FloatingIconButton__icon"
							encore-name="circle-xmark-solid"
							encore-size="12"
						></ba-icon>
					</button>
				</div>
			</div>
		`;

		var elm = $(template);
		if (withProgress) {
			elm.append(templateProgress);
		}
		return elm;
	};

	this.getProgressPercent = function(percent) {
		return percent;
	};

	this.handleFileUpload = function(that, e, config) {
		var wrapper = config.wrapper;
		config.setDragOffValues(wrapper);
		var filesDisplay = wrapper.find('.files');

		e.preventDefault();
		e.stopPropagation();
		var theFiles;
		if (typeof that.files === 'undefined') {
			theFiles = e.dataTransfer.files;//drag and drop
		} else {
			theFiles = that.files;//clicked on link
		}
		if (!Array.isArray($.fileUploader.uploaders[config.uploaderId])) {
			$.fileUploader.uploaders[config.uploaderId] = [];
		}

		for (var x = 0; x < theFiles.length; x++) {
			var fd = new FormData();
			var file = theFiles[x];
			var pieces = file.name.split('.');
			var ext = pieces[pieces.length - 1].indexOf('.') === 0 ? pieces[pieces.length - 1] : '.' + pieces[pieces.length - 1];

			//Validate the extension
			if (config.validExtensions && config.validExtensions.indexOf(ext.toLowerCase()) === -1) {
				setMessage($.__('The only allowed file extensions are: %s', config.validExtensions.join(', ')), 'error');
				return false;
			}

			//Validate the file size
			if (config.maxSize && file.size > config.maxSize) {
				setMessage('Files must be smaller than ' + config.maxSize / 1024 / 1024 + 'MB.', 'error');
				return false;
			}

			fd.append('upload',file);
			fd.append('uploaderId', config.uploaderId);
			if (config.postOptions) {
				appendPostOptions(config.postOptions, fd);
			}
			var fileWrapper = config.createFileWrapper(file.name,true);
			let $icon = fileWrapper.find('.FileType__icon');
			let iconName = getIconNameFromFileName(file.name, '16x20');
			if ($icon.length > 0) {
				if ($icon.prop('nodeName') === 'BA-FILE-ICON') {
					$icon.attr('name', file.name);
				} else if (iconName) {
					$icon.attr('name', iconName);
				}
			}
			fileWrapper.addClass('uploadInProgress');
			fileWrapper.appendTo(filesDisplay);
			filesDisplay.hide().show(0); /* force browser to redraw to pick up css once new files added to list */
			filesDisplay.addClass('active');
			var uploadToTempUrl = "/ajax/files/attachTemporary.php";
			if (config.uploadToTempUrl) {
				uploadToTempUrl = config.uploadToTempUrl;
			}
			var xhr = uploadTempFile(config, uploadToTempUrl, fd, fileWrapper, wrapper);
			$.fileUploader.uploaders[config.uploaderId].push(xhr);

			fileWrapper.find('.file-x').on('click', { xhr: xhr, fp: fileWrapper }, (event) => {
				event.stopPropagation();
				event.preventDefault();
				event.data.xhr.abort();
				var $progressBar = event.data.fp.find('.ProgressBar__bar');
				if ($progressBar.length >= 1) {
					config.removeFile(event.data.fp);
					$progressBar.animate({ 'width': '0%' }, 250, () => {
						$.fileUploader.remove(event.data.fp, config);
					});
				} else if (config.removeFile(event.data.fp)) {
					$.fileUploader.remove(event.data.fp, config);
				}
			});
		}
	};

	this.setDragOverValues = function(wrapper) {
		wrapper.addClass('dragOver').find('.links-msg').text($.__('Drop to upload')).show();
	};

	this.addEventListeners = function(wrapper, config) {
		wrapper[0].addEventListener("dragover", config.handleDragOver, false);
		config.handleDragOverStub = function (e) {
			e.preventDefault();
			e.stopPropagation();
			config.setDragOverValues($(this));
		};
		wrapper[0].addEventListener("dragenter", config.handleDragOverStub, false);
		config.doNothingStub = function(e) {
			e.preventDefault();
			e.stopPropagation();
		};
		wrapper[0].addEventListener("dragleave", config.doNothingStub, false);
		config.handleFileUploadStub = function (e) {
			config.handleFileUpload(this, e, config);
		};
		wrapper[0].addEventListener("drop", config.handleFileUploadStub, false);
	}

	this.removeEventListeners = function(wrapper, config) {
		wrapper[0].removeEventListener("dragover", config.handleDragOver);
		wrapper[0].removeEventListener("dragenter", config.handleDragOverStub);
		wrapper[0].removeEventListener("dragleave", config.doNothingStub);
		wrapper[0].removeEventListener("drop", config.handleFileUploadStub);
	}

	this.defaults = {
		handleDragOver: this.handleDragOver,
		handleFileUpload: this.handleFileUpload,
		createFileWrapper: this.createFileWrapper,
		getProgressPercent: this.getProgressPercent,
		setDragOverValues: this.setDragOverValues,
		setDragOffValues: this.setDragOffValues,
		uploadToTempUrl: this.uploadToTempUrl,
		fallbackSuccess: this.fallbackSuccess,
		addEventListeners: this.addEventListeners,
		removeEventListeners: this.removeEventListeners,
		postOptions: this.postOptions,
		error: this.error,
		removeFile: function () { return true; },
		beforeUpload: false,
		validExtensions,
	};
}

(function ($) {
	$.extend({ fileUploader: new fileUploader() });

	$.fn.fileUploader = function(options) {
		var config = $.extend({}, $.fileUploader.defaults, options);
		return this.each(function (index, item) {
			var wrapper = $(item);
			config.wrapper = wrapper;
			if (wrapper.length != 1) {
				return false;
			}
			wrapper.data('uploaderId', config.uploaderId);
			item.config = config;
			if (window.File && window.FormData && window.FileReader && window.FileList) {
				config.addEventListeners(wrapper, config);
				var link = '<input type="file" class="uploadInput" name="upload" style="visibility: hidden; width: 1px; height: 1px; position: absolute" multiple aria-label="File upload"/>' + config.linkHTML + '';
				wrapper.find('.attach-wrapper').html(link);
				const $uploadInput = wrapper.find('.uploadInput');
				$uploadInput.change(function(e) {
					config.handleFileUpload(this, e, config);
				});
				//$('.attach-link').on('click', function() {
				//	$('.uploadInput').click();
				//});

				//$('.uploadInput').change(function(e) {
				//	config.handleFileUpload(this, e, config);
				//});

				if (config.noValidate) {
					$uploadInput.addClass('ignore')
				}
				if (config.validExtensions) {
					$uploadInput.attr('accept', config.validExtensions);
				}
				if (config.acceptSingleFileOnly) {
					$uploadInput.removeAttr('multiple');
				}
				wrapper.find('.attach-link').click(function(e) {
					e.stopPropagation();
					e.preventDefault();
					$(this).prev('.uploadInput').click();
				});
				$.fileUploader.options[config.uploaderId] = config;
			} else {
				setMessage($.__('Your browser does not support this feature, if you see this message, please contact support.'), 'error');
				window.Rollbar.error('Browser support issue - File-management.mod.js');

			}
			//this has to go in the .attach-wrapper so it is a trigger for the validation and works for the iframe method too
			if (config.required) {
				wrapper.find('.attach-wrapper').append('<input type="hidden" class="inline-error" name="uploadValidatorHiddenInput" value=""/>')
			}
		});
	};
	//handle when drag and drop goes outside our target elements?
	if (window.addEventListener) {
		$('body')[0].addEventListener('dragenter',function() {
			$.each($.fileUploader.options,function(idx, item) {
				item.setDragOffValues(item);
			});
		});
	}
})(jQuery);

function appendPostOptions(options, formData) {
	$.each(options, function(index, value) {
		formData.append(index, value);
	});
}

function uploadTempFile(config, uploadToTempUrl, formData, fileWrapper, uploaderWrapper) {
	let $fileWrapperFiles = fileWrapper.closest('div.files');
	setUploadingStatus();
	if (config.beforeUpload && typeof config.beforeUpload === 'function') {
		config.beforeUpload.call(null, {
			configuration: config,
			fileUploadUrl: uploadToTempUrl,
			formData: formData,
			fileWrapper: fileWrapper,
			uploaderWrapper: uploaderWrapper
		});
	}

	return $.ajax({
		url: uploadToTempUrl,
		type: 'post',
		data: formData,
		processData: false,
		contentType: false,
		dataType: 'json',
		context: fileWrapper, //'this' in success and other callbacks
		success: function (data) {
			this.removeClass('uploadInProgress');

			if (data.status.toUpperCase() === 'OK') {
				let $icon = this.find('.FileType__icon');
				let iconName = data.name && getIconNameFromFileName(data.name, '16x20');
				if ($icon.length > 0) {
					if ($icon.prop('nodeName') === 'BA-FILE-ICON') {
						$icon.attr('name', data.name);
					} else if (iconName) {
						$icon.attr('name', iconName);
					}
				}
				this.addClass('uploadDone');
				this.find('.ProgressBar__bar').css('width', '100%');
				this.find('.files-progress-wrapper').fadeOut(1500, function () {
					$(this).remove();
					setUploadingStatus();
				});
				this.data('fileInfo', data);
				this.attr('id', `file-${ data.fid }`);
				$fileWrapperFiles.siblings('.save-buttons').find('a.btn').removeClass('disabled');
				if (config.success) {
					config.success(data,this);
				}
			} else {
				const $fileFieldDiv = $(this.closest('.fieldDiv'));
				this.fadeOut(1500, () => {
					$.fileUploader.remove(this, config);
					setUploadingStatus();
					$fileFieldDiv.find('.noFile').show();
					$fileFieldDiv.find('.attach-wrapper').show();
				});
				$('html, body').animate({ scrollTop: 0 }, 450, 'easeInOutQuad', function () {
					if (this.nodeName === 'BODY') {
						return;
					}
					setMessage(data.message, 'error');
				});
			}
			if (config.required) {
				if (this.closest('.fieldDiv').find('input[name=uploadValidatorHiddenInput]').valid()) {
					this.closest('.fieldDiv').find('.fieldInfo.error').remove();
				}
			}
		},
		error: function (data) {
			this.removeClass('uploadInProgress');
			this.addClass('uploadDone');
			this.find('.ProgressBar__bar').css('width', '100%');
			this.fadeOut(1500, () => {
				$.fileUploader.remove(this, config);
				setUploadingStatus();
			});
			this.data('fileInfo', data);
			this.attr('id', `file-${ data.fid }`);
			$(this).closest('div.files').siblings('.save-buttons').find('a.btn').removeClass('disabled');
			if (config.error) {
				config.error(data);
			}
			if (data.status == 403) {
				setMessage('Unable to upload file due to its contents.', 'error');
			}
		},
		complete: function (data) {
			//we clear out the uploadInput, so that they can upload the same file twice,
			//and just in case our file input gets included in the form submission, we don't reupload the files
			uploaderWrapper.find('.uploadInput').val('');
			this.removeClass('uploadInProgress');
			document.dispatchEvent(new CustomEvent('fileUploadComplete'));
		},
		xhr: function () {
			var bar = fileWrapper.find('.ProgressBar__bar');
			var myXhr = $.ajaxSettings.xhr();
			if (myXhr.upload) {
				myXhr.upload.addEventListener('progress', function (e) {
					var percent = Math.round((e.loaded / e.total) * 100);
					bar.css('width', `${ percent }%`);
					if (config.getProgressPercent) {
						config.getProgressPercent(percent);
					}
				}, false);
			}
			return myXhr;
		}
	});

	function setUploadingStatus() {
		$fileWrapperFiles.toggleClass('uploadingDone', $fileWrapperFiles.find('.files-progress-wrapper').length === 0);
	}
}
