import { PASSWORD_TYPE } from 'manage-session.mod/constants';

import { PasswordLoginForm } from './components/password-login-form.react';
import { SamlLoginForm } from './components/saml-login-form.react';
import { LOCK_ICON } from './constants';

export const openLockModal = (props) => {
	const { onOpen, primaryAction, urlPrefix, ...rest } = props;

	const mobileFriendlyEnabled = props.isOnboardingUser;
	const hasSso = props.formType !== PASSWORD_TYPE;
	const Form = hasSso ? SamlLoginForm : PasswordLoginForm;

	const modalOptions = {
		alternativeAction: () => {
			document.location.href = `${urlPrefix}login.php?logout=1`;
		},
		alternativeActionText: $.__('Log Out'),
		content: <Form ssoButtonClickAction={hasSso ? primaryAction : null} {...rest} />,
		hasCloseButton: false,
		header: false,
		hideActions: hasSso,
		icon: LOCK_ICON,
		iconV2Color: 'primary-strong',
		isHeadless: true,
		isMobileFriendly: mobileFriendlyEnabled,
		isMobileFriendlySheetFullScreen: mobileFriendlyEnabled,
		isOpen: true,
		primaryAction,
		primaryActionKey: 'Enter',
		primaryActionText: $.__('Log In'),
		onOpen: () => {
			if (!hasSso) {
				$('.js-passwordInput').focus();
			}
		},
		onlyPrintModal: true,
		type: 'small',
	};
	window.BambooHR.LockModal.setState(modalOptions);
};

export const closeLockModal = () => {
	window.BambooHR.LockModal.setState({ isOpen: false });
};
